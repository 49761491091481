const currentRouteReducer =  (state=null,action)=>{
       switch(action.type){
            case 'current_route':
             return action.payload;
            default:
                return state;
       }
}

const authInfoReducer =  (state=null,action)=>{
       switch(action.type){
            case 'auh_info_set':
             return action.payload;
            default:
                return state;
       }
}



// Branch set

const createdBranchReducer = (state=null,action)=>{
       switch(action.type){
              case 'created_branch_set':
               return action.payload;
              default:
                  return state;
         }
}
const updatedBranchReducer = (state=null,action)=>{
       switch(action.type){
              case 'updated_branch_set':
               return action.payload;
              default:
                  return state;
         }
}
const branchDisableRestoreReducer = (state=null,action)=>{
       switch(action.type){
              case 'disable_restore_branch_set':
               return action.payload;
              default:
                  return state;
         }
}
// Warehouse set

const createdWarehouseReducer = (state=null,action)=>{
       switch(action.type){
              case 'created_warehouse_set':
               return action.payload;
              default:
                  return state;
         }
}
const updatedWarehouseReducer = (state=null,action)=>{
       switch(action.type){
              case 'updated_warehouse_set':
               return action.payload;
              default:
                  return state;
         }
}
const warehouseDisableRestoreReducer = (state=null,action)=>{
       switch(action.type){
              case 'disable_restore_warehouse_set':
               return action.payload;
              default:
                  return state;
         }
}



export {currentRouteReducer,authInfoReducer,
       createdBranchReducer,updatedBranchReducer,branchDisableRestoreReducer,
       createdWarehouseReducer,updatedWarehouseReducer,warehouseDisableRestoreReducer,

}